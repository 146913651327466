<template>
    <modal-content ref="add-file" name="add-file" title="Add File" width="480px" :showFooter="false" @beforeOpen="onBeforeOpen">
        <div class="px-6 py-4 bg-card-bg">
            <!-- <div class="border border-dashed border-primary rounded-md flex flex-col items-center py-4 px-6" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <file-upload />
                <span class="text-primary text-lg mt-2">Drag and Drop File</span>
                <span class="text-gray-600 mt-4">.jpg, .png, .ai, .svg or .pdf & others</span>
            </div> -->
            <file-pond name="files" ref="pond" credits="false" class-name="my-pond" label-idle="Drop files here..." allow-multiple="false" :accepted-file-types="fileTypes" v-bind:files="myFiles" v-on:init="handleFilePondInit" />
            <span class="text-gray-500"> <small>.jpg, .png, .svg, .pdf, .doc, .docx, .xlsx or .xls </small></span>
            <!-- <div class="relative flex py-5 items-center">
                <div class="flex-grow border-t border-primary"></div>
                <span class="flex-shrink mx-4 text-primary">OR</span>
                <div class="flex-grow border-t border-primary"></div>
            </div> -->
            <button type="button" class="mt-4 text-white bg-primary hover:bg-primary-focus font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full" @click="onAddFiles()">Add</button>
        </div>
    </modal-content>
</template>

<script>
// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

import ModalContent from "@shared/modal-content";

// import FileUpload from "@shared/assets/upload.svg";

export default {
    name: "AddFile",
    data() {
        return { 
            myFiles: [],
            fileTypes: ["image/jpeg", "image/jpg", "image/svg", "image/png", "application/pdf",'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        };
    },
    components: {
        ModalContent,
        // FileUpload,
        FilePond,
    },
    methods: {
        showModal() {
            this.$refs["add-file"].showModal();
        },
        onBeforeOpen() {
            // this.folderName = "";
            // this.description = "";
            // this.$v.$reset();
        },
        handleFilePondInit() {
            // example of instance method call on pond reference
            this.$refs.pond.getFiles();
        },
        onAddFiles() {
            if (this.$refs.pond?.getFiles()?.length > 0) {
                const type = this.$refs.pond.getFiles()?.[0]?.file.type;
                if (this.fileTypes.includes(type)) {
                    this.$emit("on-add-file", { files: this.$refs.pond.getFiles() } );
                    this.$nextTick(() => {
                        this.$refs["add-file"].hideModal();
                    });
                } else {
                    this.$toast.error(".jpg, .png, .svg, .pdf, .doc, .docx, .xlsx or .xls")
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep{
        .filepond--file-info {
            transform: translate3d(38px, 0px, 0px) !important;
            margin-left: -0.5em !important;
        }
    }
</style>
